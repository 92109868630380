/*eslint-disable*/
import React, { useState } from "react"
import axios from "axios"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormHelperText from "@material-ui/core/FormHelperText"
import Button from "@material-ui/core/Button"

// @material-ui/icons
import Email from "@material-ui/icons/Email"

// core components
import GridContainer from "../components/material-kit-pro/components/Grid/GridContainer"
import GridItem from "../components/material-kit-pro/components/Grid/GridItem"
import Card from "../components/material-kit-pro/components/Card/Card"
import CardBody from "../components/material-kit-pro/components/Card/CardBody"
import CardHeader from "../components/material-kit-pro/components/Card/CardHeader"
import CustomInput from "../components/material-kit-pro/components/CustomInput/CustomInput"

// own
import Footer from "../components/layout/footer"
import Header from "../components/layout/header"
import SEO from "../components/seo"
import BackgroundImageDots from "../components/BackgroundImageDots"

import loginPageStyle from "../components/material-kit-pro/styles/loginPageStyle"

const useStyles = makeStyles(loginPageStyle)

export default function ForgotPasswordPage({ location }) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  })
  const classes = useStyles()

  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const changeEmail = e => {
    setEmail(e.target.value)
  }

  const onSubmit = e => {
    e.preventDefault()
    const api = process.env.GATSBY_STRAPI_API

    axios
      .post(api + "/auth/forgot-password", {
        email,
      })
      .then(response => {
        setSuccess(
          `An email was sent to ${email}. Please click on the provided link in the email to recover your account.`
        )
        setErrorMessage("")
      })
      .catch(e => {
        if (
          e.response.data.message &&
          e.response.data.message[0] &&
          e.response.data.message[0].messages &&
          e.response.data.message[0].messages[0]
        ) {
          setErrorMessage(e.response.data.message[0].messages[0].message)
        } else {
          setErrorMessage(e.message)
        }
      })
  }

  return (
    <div>
      <Header compact />
      {success && <Alert severity="success">{success}</Alert>}
      <BackgroundImageDots className={classes.pageHeader}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form onSubmit={onSubmit} className={classes.form}>
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h1
                      className={classes.cardTitle}
                      style={{ fontSize: "1.15em" }}
                    >
                      Forgot Password
                    </h1>
                  </CardHeader>
                  <CardBody signup>
                    <CustomInput
                      id="email"
                      name="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        ariaLabel: "Email",
                        placeholder: "Email",
                        type: "email",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        onChange: changeEmail,
                      }}
                    />
                  </CardBody>
                  <FormHelperText
                    className={classes.textCenter}
                    error={!!errorMessage}
                  >
                    {errorMessage}
                  </FormHelperText>
                  <div className={classes.textCenter}>
                    <Button
                      type="submit"
                      round
                      variant="contained"
                      color="primary"
                    >
                      Send Recovery Email
                    </Button>

                    <p>
                      Not a member yet?{" "}
                      <Link className={classes.link} to="/signup">
                        Register now.
                      </Link>
                    </p>
                    <p>
                      Already have an account?{" "}
                      <Link className={classes.link} to="/login">
                        Log In
                      </Link>
                      .
                    </p>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </BackgroundImageDots>
      <Footer withFlex />
      <SEO
        general={{
          title: "Reset your password | Simpleen.io",
          description:
            "Forgot your password? Enter your email address and we will send you a recovery link.",
          language: "en",
          path: location.pathname,
        }}
      />
    </div>
  )
}
